












































  import { GENDER, LIFE_INSURANCE_PROTECTIONS, PRODUCTS } from '@/constant/Enums';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component({
    name: 'compareLifeInsurance',
    components: {},
    mixins: [],
  })
  export default class compareLifeInsurance extends Vue {

    @Prop({ default: '' }) title!: string;
    @Prop({ default: '' }) desciption!: string;
    @Prop({ default: 'BANDINGKAN SEKARANG' }) buttonTitle!:string

    public form = {
      protection: 0,
      gender: 0,
      birthdate: null,
    };

    public LIFE_INSURANCE_PROTECTIONS = LIFE_INSURANCE_PROTECTIONS;
    public GENDER = GENDER;

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
    /**
     * OnSubmit
     */
    public OnSubmit() {
      this.$router.push({name: 'compare-'+PRODUCTS[5].name.toLowerCase().replace(' ', '-')})
    }
  }
